/* Example CSS */
.custom-left-button,
.custom-right-button {
  background-color: #f1f1f1;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.custom-left-button {
  float: left;
}

.custom-right-button {
  float: right;
}