@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body{
  font-family: 'IBM Plex Sans', sans-serif;
}

html {
  box-sizing: border-box;
}

p {
  font-size: 30px;
  line-height: 1.5;
}

div {
  font-family: 'IBM Plex Sans', sans-serif;
}

button {
  background-color: transparent;
  border: 1.5px solid rgb(68, 68, 68);
  color: rgb(75, 77, 80);
  font-size: 1rem;
  max-width: 100px;
  padding: 0.5rem 0.5rem;
  font-family: 'IBM Plex Sans', sans-serif;
}

button:hover {
  cursor: pointer;
  color: rgb(0, 0, 0);
  background-color: rgb(190, 190, 190);
}

.container {
  margin: 0 auto;
  max-width: 600px;
  overflow: auto;
  text-align: center;
}

/* ----------- iPhone 12, 12 Pro ----------- */
 
/* 2532x1170 pixels at 460ppi */ 
@media only screen 
and (device-width: 390px) 
and (device-height: 844px) 
and (-webkit-device-pixel-ratio: 3) { 

}

.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 10rem;
  margin-top: 5rem;
}

.form input {
  display: block;
  width: 100%;
  height: 25px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}
