footer {
  /* position: fixed; */
  bottom: 0;
  /* font-size: 3rem; */
  left: 0;
  padding-bottom: 5rem;
  text-align: center;
  width: 100%;
}
.footertext{
    font-size: 1.2rem;
}
