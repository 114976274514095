
/* iphone media queries */
@media only screen 
and (min-device-height: 350px) 
and (max-device-width: 420px) {
    .thumbnailimage{
        width: 16rem !important;
        height: 10rem !important;
    }
    .hptitle{
        font-size: 1rem !important;
    }
    .hpchild{
        padding: 1.5rem !important;
        margin: 2rem !important;
    }
    .lineunderelement{
        padding: 0;
        margin: 0;
    }

}


.body{
    font-family: 'IBM Plex Sans', sans-serif;
}

.allpropertiestxt{
    font-size: 2rem;
}


.hptitle{
    font-family: IBM Plex Sans, sans-serif;
    font-size: 1.6rem;
    position: relative;
    top: 0.8rem;
    text-transform: capitalize;
    font-weight: 700;
    color: #353535;
    letter-spacing: .25rem;
}
.hplocation{
    font-size: 1rem;
    position: relative;
    top: 2.37rem;
}

.hpcontainer{
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
}

.hpchild{
    margin: 4%;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.11);
    padding: 2rem;
    box-shadow: -5px 10px 27px 0px rgba(25, 25, 25, 0.22);
}

.titlecontainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.thumbnailimage{
    width: 27rem;
    height: 17rem;
}

.homepagebody{
    background-color: #F7F7F7;
}
.homepagebody .navbar {
    background-color: #f7f7f7; /* Set the navbar background color for this page only */
}


.lineunderelement{
    border-bottom: 1px solid #000;
    padding-bottom: 3rem;
    margin-left: 12rem;
    margin-right: 12rem;
}

