


.ppcontainer{
    margin: 0 auto;
    max-width: 80%;
    overflow: auto;
    text-align: center;    
}

.h1{
    font-family: 'IBM Plex Sans', sans-serif; 
}


.videocontainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.vcchild{
    margin: 2rem;
    margin-top: 2rem;
    width: "800" ;
    height: "550";
}

.ppchild{
    margin-bottom: 8%;
    margin-left: 7%;
    margin-right: 7%;

}

.lineunderelement{
    border-bottom: 1px solid #000;
    padding-bottom: 3rem;
    margin-left: 12rem;
    margin-right: 12rem;
}

a {
    text-decoration: none;
  }

.pptitles {
    font-size: larger;
}

h1{
    /* font-family: 'IBM Plex Sans', sans-serif;; */
    font-size: 7rem;
    color: black;
    font-weight: 200;
}

h2{
    /* font-family: 'IBM Plex Sans', sans-serif; */
    font-size: 30px;
    margin-bottom: 2rem;
    font-weight: bold;
    letter-spacing: 10px;
    text-transform: uppercase;
    margin-top: 6rem;
}

h3{
    /* font-family: Kohinoor Telugu; */
    font-size: 1.5rem;
    color: rgb(0, 0, 0);
    font-weight: 100;
}

p{
    font-size: 16px;
    letter-spacing: 1.6px;
}

.imagechild{
    margin: 1rem
}

.mrpropertynameandloc{
    color:white;
    padding: 4.5rem; /* Adjust as needed */
    letter-spacing: .4rem;
    /* background-image: url(./MonkeyRanch/NewBestofMonkeyRanch/MonkeyRanchPPThumbnail.jpg); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 30rem;
    width: 100vw; /* Full viewport width */
    margin: 0; /* Ensures no extra space around the div */
    position: relative;
    left: 0; /* Ensures it starts at the left */
    box-sizing: border-box; /* Includes padding in the width */
    margin-bottom: 2rem;
    text-align: center;
}

/* PROPERTY PAGE THUMBNAILS */

.mrthumbnail {
    background-image: url(./MonkeyRanch/NewBestofMonkeyRanch/MonkeyRanchPPThumbnail.jpg)
}

.jhthumbnail {
    margin-top: 4rem;
    background-image: url(./MonkeyRanch/BestofJungleHouse/JHThumbnail4.png);
    margin-bottom: 2rem;

}

.lothumbnail{
    margin-top: 4rem;
    background-image: url(./LaOnda/BestofLaOnda/LOThumbnail.png);
    margin-bottom: 2rem;   
}

.bathumbnail{
    
    background-image: url(./BuenosAires/BestofBuenosAires/BAThumbnail.png);
    margin-bottom: 2rem;  
}

.rhthumbnail{

    background-image: url(./RockHouse/RockHouseImages/RHThumbnail.png);
    margin-bottom: 2rem;    
}



/* .ocean-view {
    background-image: url('./MonkeyRanch/NewBestofMonkeyRanch/OceanViewThumbnail.jpg');
} */

.p-name{
    color:white;
    text-transform: uppercase;
    font-size: 4rem;
    font-weight: 500;
    
}

.p-name, .p-location {
    position: relative;
    top:6rem;
}

.aboutinfo{
    margin-top: 2rem;
}



.secondtitle{
    font-size: 4.5rem;
}

.googlemap{
    width: 100%;
    height: 30rem
}

.agentinfo{
    padding-top: 2rem;
}

@media (max-width: 45em) {
    .ppchild{
        margin-left: 2%;
        margin-right: 2%;
    }
    .mrpropertynameandloc {
        margin-bottom: 6rem;
        margin-left: 0;
    }
    .p-name {
        font-size: 2.8rem;
    }
    p {
        font-size: 14px;
    }
    .ppchild{
        margin-bottom: 20%;
    }
    .lineunderelement{
        margin-left: 8rem;
        margin-right: 8rem;
    }
}

@media (max-width: 35em){
    .mrpropertynameandloc {
        margin-bottom: 4rem;
        height: 15rem;
        margin-left: 0;
    }
    .p-name, .p-location {
        position: relative;
        top: -1rem;
    }
    .p-name{
        font-size: 2rem;
    }
    .p-location{
        padding-top: .5rem;
    }
    h2{
        font-size: 25px;
        letter-spacing: 7.5px;
        margin-top: 0%;
    }
    .lineunderelement{
        padding: 0;
        margin: 0;
    }
}
