
ul {
  justify-content: space-between;
  list-style-type: none;
}


.navBar {
  padding: 1.5rem 2rem;
  height: auto; 
  /* margin-bottom: 2rem; */
  background-color: white;
  color: white;
}


/* Center Title with Flexbox */
.title-container {
  display: grid;
  justify-content: center; /* Center title content */
  align-items: center;
  position: relative;
  bottom: 20px;
}


.title {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 3.5rem;
  font-weight: bold;
  letter-spacing: .7rem;
  color: #353535;
}

.little-title{
  font-size: .7rem;
  letter-spacing: .07rem;
}

.navBar button {

  background: transparent;
  border: 0px solid rgb(0, 0, 0);
  color: #353535;
  padding: 0.4rem 0.5rem;
  text-transform: uppercase;
  position: absolute;
  top: 65px;
  right: 69px;
  font-family: 'IBM PLEX SANS' !important
}
  


.navBar button:hover {
  background: white;
  color: rgb(110, 110, 110);
  cursor: pointer;
}

.brand {
  cursor: pointer;
  font-size: 2rem;
}

@media (max-width: 35em){
  .title{
    font-size: 3rem;
  }
  .little-title{
    font-size: .7rem;
    letter-spacing: normal;
  }
  .navBar button {
    font-size: .5rem;
    top: 23px;
    right: 30px;
  }
  .title-container {
    bottom: 0px;
  }
  .navBar{
    padding: 0;
    padding-bottom: 1rem;
  }
  
}